.suviapp {
  max-width: 500px;
  display: block;
  margin: auto;
  background-color: white;
  width: 100%;
  min-height: 100vh;
}

#root {
  background-color: #f1f1f1;
}

.text-name {
  font-size: 16px;
  color: green;
}

.table-list {
  overflow-y: auto;
}

.table-list::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
}

.logo img {
  width: 50px;
}

.header__text {
  font-size: 22px;
  text-transform: uppercase;
  padding-left: 12px;
  font-weight: bold;
  color: green;
}

.table-list {

  th,
  td {
    font-size: 12px;
    vertical-align: middle;
  }
}

.claim-btn {
  font-size: 10px !important;
  align-items: center;
  gap: 3px;
  width: 80px;
}

.header {
  border-bottom: 1px solid grey;
}

@media (max-width:455px) {
  .header__text {
    font-size: 18px;
  }
}

@media (max-width:390px) {
  .header__text {
    font-size: 15px;
  }
}

@media (max-width:340px) {
  .header__text {
    font-size: 13px;
  }
}

.pt-75 {
  padding-top: 137px;
}

.header-width {
  max-width: 500px;
  width: 100%;
  border-bottom: 1px solid grey;
}

.amount_box {
  background-color: #f2f2f2;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 6px 6px;
  width: 75px;
}

.amount_box:focus {
  border: 1px solid grey;
  outline-color: blue;
}